<template>
  <b-overlay
    :show="showOverlay"
    no-fade
    opacity="0.9"
    variant="light">
    <div>

      <b-card v-if="paymentWithAppMade">
        <p>A first payment has already been made with this information. No changes are can be made.</p>
      </b-card>
      <b-card v-else-if="paymentInformationCaptured">
        <p>Payment Information has already been collected.</p>
        <b-button variant="warning" type="small" @click="clearPaymentInformation">Click to clear and re-enter Payment Information</b-button>
      </b-card>
      <b-card v-else no-body>
        <b-tabs card>
          <b-tab title="Pay via ACH transfer" :active.sync="bankTabIsActive"> <!-- the bank tab -->
            <b-form ref="bankForm" :validated="bankFormValidate">
              <b-form-group
                label="Account Holder Type"
              >
                <b-form-radio-group

                  class="pw-2"
                  v-model="bankHolderType"
                  :options="['personal', 'business']"
                  name="bankHolderType"
                  buttons
                  size="sm"
                  button-variant="outline-primary"
                >
                </b-form-radio-group>
              </b-form-group>

              <b-form-group
                label="Account Type"
              >
                <b-form-radio-group

                  class="pw-2"
                  v-model="bankType"
                  :options="['checking', 'savings']"
                  name="bankAccountType"
                  buttons
                  size="sm"
                  button-variant="outline-primary"
                >
                </b-form-radio-group>
              </b-form-group>

              <b-form-group
                label="Bank Routing Number"
                invalid-feedback="This field is required and must be 9 digits."
              >
                <b-form-input v-mask="'000000000'" v-model="bankRouting" placeholder="Routing Number" trim
                              :required="bankTabIsActive" :state="!!(bankRouting) && bankRouting.length === 9"
                              invalid-feedback="A valid 9-digit routing number is required."
></b-form-input>
              </b-form-group>

              <b-form-group
                label="Bank Account Number"
                invalid-feedback="This field is required."
              >
                <b-form-input v-mask-number v-model="bankAccount" placeholder="Account Number" trim
                              :required="bankTabIsActive" :state="!!bankAccount"></b-form-input>
              </b-form-group>
            </b-form>
          </b-tab>

          <b-tab title="Pay via credit card" :active.sync="cardTabIsActive">
            <b-form ref="cardForm" :validated="cardFormValidate">
              <b-form-group
                label="Card Number"
              >
                <div id="cardNumber"></div>
                <b-form-text v-if="cardNumberIsValid === true" text-variant="success">
                  <font-awesome-icon v-if="cardType === 'visa'" :icon="['fab', 'cc-visa']" />
                  <font-awesome-icon v-else-if="cardType === 'master'" :icon="['fab', 'cc-mastercard']" />
                  <font-awesome-icon v-else-if="cardType === 'american_express'" :icon="['fab', 'cc-amex']" />
                  <font-awesome-icon v-else-if="cardType === 'discover'" :icon="['fab', 'cc-discover']" />
                  <font-awesome-icon v-else icon="credit-card" />
                </b-form-text>
                <b-form-text v-if="cardNumberIsValid === false" text-variant="danger">
                  A valid credit card is required.
                </b-form-text>

<!--                <b-form-input v-mask-cc v-model="cardNumber" placeholder="Card Number" trim-->
<!--                              :required="cardTabIsActive"></b-form-input>-->
              </b-form-group>
              <b-form-group
                label="CVV"
              >
                <div id="cardCVV"></div>
                <b-form-text v-if="cardCvvIsValid" text-variant="success">
                  CVV <font-awesome-icon icon="check" />
                </b-form-text>
                <b-form-text v-else text-variant="danger">
                  A valid CVV is required.
                </b-form-text>

                <!--                <b-form-input v-mask="''"v-model="cardNumber" id="cardCVV" placeholder="Card Number" trim-->
<!--                              :required="cardTabIsActive"></b-form-input>-->
              </b-form-group>
              <b-form-group
                label="Card Expiration Month"
                :state="!!(cardExpMonth)"
                invalid-feedback="A valid month is required"
              >
                <b-form-select id="cardExpMonth" v-model="cardExpMonth" :options="[1,2,3,4,5,6,7,8,9,10,11,12]"
                               :required="cardTabIsActive" :state="!!cardExpMonth"></b-form-select>
              </b-form-group>
              <b-form-group
                label="Card Expiration Year"
                :state="!!(cardExpYear)"
                invalid-feedback="A valid year is required."
              >
                <b-form-select id="cardExpYear" v-model="cardExpYear" :options="[2022,2023,2024,2025,2026,2027,2028,2029,2030,2031,2032]"
                               :required="cardTabIsActive" :state="!!cardExpYear"></b-form-select>
              </b-form-group>
            </b-form>
          </b-tab>
        </b-tabs>
      </b-card>

      <b-modal
        :title="`Increase in premium due to date of birth`"
        v-model="showChangePremium"
        tabindex="-1"
        role="dialog"
        aria-hidden="true"
        hide-header-close
        :no-close-on-backdrop="true"
        v-if="applicantHistory.length>0"
        >

        <b-container>
          This date will require an increase in premium from ${{ previousPremium.toFixed(2)  }} to ${{ changedPremium.toFixed(2) }} due to date of birth for {{ applicantHistory.join(', ') }} 
        </b-container>

        <div slot="modal-footer" class="row align-self-end">
          <b-btn size="md" class="col-0 mr-3" variant="outline-secondary" @click="revertDatePremium">
            <i class="fa fa-times"></i> No, go back
          </b-btn>
          <b-btn size="md" class="col-0 mr-3" variant="danger" @click="changePremium">
            Yes, change premium
          </b-btn>
        </div>
      </b-modal>

      <b-modal
        :title="`Lowered premium due to date of birth`"
        v-model="showPremiumDecreased"
        tabindex="-1"
        role="dialog"
        aria-hidden="true"
        hide-header-close
        :no-close-on-backdrop="true"
        v-if="premiumChangedHistory.length>0"
        >

        <b-container>
          This date has enabled a lower premium from ${{ previousPremium.toFixed(2) }} to ${{ changedPremium.toFixed(2) }} due to date of birth for {{ premiumChangedHistory.join(', ') }} 
        </b-container>

        <div slot="modal-footer" class="row align-self-end">
          <b-btn size="md" class="col-0 mr-3" variant="outline-secondary" @click="decreasePremium">
            <i class="fa fa-times"></i> OK
          </b-btn>
        </div>
      </b-modal>

      <b-modal
        :title="`Max Age`"
        v-model="showMaxAgePopup"
        tabindex="-1"
        role="dialog"
        aria-hidden="true"
        hide-header-close
        :no-close-on-backdrop="true"
        v-if="maxAgeApplicants.length>0"
        >

        <b-container>
          This date will make {{ maxAgeApplicants.join(', ') }} ineligible due to date of birth. 
        </b-container>

        <div slot="modal-footer" class="row align-self-end">
          <b-btn size="md" class="col-0 mr-3" variant="outline-secondary" @click="revertDate">
            <i class="fa fa-times"></i> OK
          </b-btn>
        </div>
      </b-modal>

      <b-card title="Payment Schedule" class="mt-4" v-if="!paymentWithAppMade">
        <b-form>
          <b-form-group>
            <b-form-checkbox
              v-model="paymentWithApp"
            >
              If approved, pay first month's premium when this application is submitted.
            </b-form-checkbox>
          </b-form-group>

          Please pay premium according to the
          <b-form-radio-group
            class="pw-2"
            id="radio-group-1"
            v-model="paymentScheduleType"
            :options="paymentScheduleTypes"
            name="paymentScheduleType"
            buttons
            size="sm"
            button-variant="outline-primary"
          >
          </b-form-radio-group>
          of the month:
        </b-form>

        <div class="m-2">
          <b-form inline>
            <div v-show="paymentScheduleType === 'monthly-day-of-month'">
              on the
              <b-form-datepicker
                id="datepicker-full-width"
                size="sm"
                v-model="paymentScheduleDayOfMonth"
                :min="new Date().toISOString().split('T')[0]"
                :max="maxDate"
                :date-disabled-fn="dateDisabledDay"
                :date-format-options="{ year: undefined, month: undefined, day: 'numeric' }"
              ></b-form-datepicker>
              of each month.  
            </div>
            <div v-show="paymentScheduleType === 'monthly-day-of-week'">
              on the
              <b-form-datepicker
                id="datepicker-full-width"
                size="sm"
                v-model="paymentScheduleDayOfWeek"
                :min="new Date().toISOString().split('T')[0]"
                :max="maxDate"
                :date-disabled-fn="dateDisabled"
                :date-format-options="{ year: undefined, month: undefined, day: 'numeric' }"
              ></b-form-datepicker>
              of each month.
            </div>
          </b-form>

        </div>

        <p v-if="firstNextPaymentDateString">Your {{paymentFirstNextText}} will be <strong><span
          v-html="firstNextPaymentDateString"></span></strong>
          and every <strong><span v-html="firstNextPaymentDateSecondString"></span></strong> of the month after that.
        </p>
        <p v-else><em>Please choose the payment schedule information.</em></p>
      </b-card>

      <b-alert variant="danger" :show="pciErrors.length > 0" class="mt-2 mb-2">
        <div>Processing payment info resulted in these errors:</div>
        <ul>
          <li v-for="error in pciErrors">{{ error }}</li>
        </ul>
      </b-alert>

      <div class="text-right mt-4" v-if="!paymentWithAppMade">
        <b-button v-if="paymentInformationCaptured" @click="saveSchedule" variant="success" :disabled="!canUpdatePaymentSchedule">Update Payment Schedule</b-button>
        <b-button v-else @click="submitPaymentInformation" variant="success" :disabled="!canSubmitPaymentInformation">Save Payment Information</b-button>
      </div>

    </div>
  </b-overlay>
</template>

<script>
import moment from "../vendor/moment-timezone-with-data-1970-2030.js";
import Api from "../api";
import {Applicant, ApplicantList, Coverage, CoverageList, PremiumMode, ProductList} from "./models";
import bus from "./messages";
const payment_schedule_button = (process.env.TENANT === 'LEADERS') ? "week" : "week(Soc. Sec.)";

export default {
  name: "PaymentInformationCapture",
  props: {
    applicants: {
      type: ApplicantList,
      required: true,
    },
    products: {
      type: ProductList,
      required: false,
    },
    selectedCoverages: {
      type: CoverageList,
      required: true
    },
    applicantContext: {
      type: Applicant,
      required: false
    },
    coverageContext: {
      type: Coverage,
      required: false
    },
    premiumMode: {
      type: PremiumMode,
      required: true
    },
    globalBeneficiaries: {
      type: Array,
      required: false
    },
    paymentInformation: {
      type: Object,
      required: false
    },
    // this is filled-in from the backend
    currentPaymentSchedule: {
      type: Object,
      required: false,
    },
    paymentWithAppDefault: {
      type: Boolean,
      required: false,
      default: false
    },
    rates: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      showOverlay: false,
      paymentCustomerId: null,
      paymentMethodId: null,

      paymentWithApp: null,
      paymentWithAppDate: null,
      paymentWithAppProcessorId: null,

      bankTabIsActive: true,
      cardTabIsActive: false,

      cardNumber: '',
      cardExpMonth: '',
      cardExpYear: '',
      cardCvv: '',

      bankType: 'checking', // or 'savings'
      bankHolderType: 'personal', // or 'business'
      bankAccount: null,
      bankRouting: null,

      // see computed paymentMethodType, which is computed from the active tab index
      paymentScheduleType: 'monthly-day-of-month', // or 'monthly-day-of-week'
      paymentScheduleTypes: [
        {value: 'monthly-day-of-month', text: 'day'},
        {value: 'monthly-day-of-week', text: payment_schedule_button},
      ],
      paymentScheduleDayOfMonth: new Date(), // should be 1-28
      // see computed paymentScheduleDaysOfMonth
      paymentScheduleDayOfWeek: new Date(), // 0-6
      paymentScheduleWeekdayOccurrence: 1, // 1-4
      paymentScheduleWeeksOfMonth: [
        {value: 1, text: 'First'},
        {value: 2, text: 'Second'},
        {value: 3, text: 'Third'},
        {value: 4, text: 'Fourth'},
      ],
      paymentScheduleDaysOfWeek: [
        {value: 0, text: 'Sunday'},
        {value: 1, text: 'Monday'},
        {value: 2, text: 'Tuesday'},
        {value: 3, text: 'Wednesday'},
        {value: 4, text: 'Thursday'},
        {value: 5, text: 'Friday'},
        {value: 6, text: 'Saturday'},
      ],
      // Examples from Stax docs:
      // **4th SUNDAY EVERY MONTH**
      // FREQ=MONTHLY;BYSETPOS=4;BYDAY=SU;INTERVAL=1
      // **17th of every month**
      // FREQ=MONTHLY;BYMONTHDAY=17;INTERVAL=1

      pciErrors: [],

      canContinue: false,
      canUpdatePaymentSchedule: false,
      paymentScheduleHasChanged: false,

      cardType: null,
      cardNumberIsValid: false,
      cardCvvIsValid: false,

      // this is initialized in created()
      bankProcessor: null,
      cardProcessor: null,

      bankFormValidate: false,
      cardFormValidate: false,
      previouslySelectedDatOfMonth: null,
      maxAgeApplicants: [],
      showMaxAgePopup: false,
      applicantHistory: [],
      premiumChangedHistory: [],
      showChangePremium: false,
      premiumChanged: false,
      showPremiumDecreased: false,
      previousPremium: 0,
      changedPremium:0,
      firstLoad: true,
      forceWeekday: false
    }
  },
  created() {   
    bus.$on("handle-updated-rates", this.handleUpdatedRates);
    // initialize with the default prop
    this.paymentWithApp = this.paymentWithAppDefault;
    console.debug(`Applied default paymentWithApp=${this.paymentWithApp}`);

    this.initBankProcessor();
    this.initCardProcessor();

    if (this.currentPaymentSchedule) {
      this._applyCurrentPaymentScheduleLocally();
    } else {
      // set defaults for first time, based on current date
      this.paymentScheduleDayOfMonth = this._defaultDayOfMonth;
      this.paymentScheduleDayOfWeek = this._defaultDayOfMonth;
    }

    // emit the value on the global event bus so other components have
    // the newest payment schedule info
    this.emitValue();

    // set state of the Next button upon creation
    bus.$emit('update-force-next-step-disabled', !(this.paymentMethodId && this.paymentCustomerId));
  },
  watch: {
    currentPaymentSchedule() {
      // update the value locally
      this._applyCurrentPaymentScheduleLocally();
    },

    paymentWithApp: function(newVal, oldVal) {
      if (newVal) {
        if(this.firstLoad){
          this.setCurrentDate();
          return;
        }
        this.canUpdatePaymentSchedule = true;
        let newDate;
        if(this.paymentScheduleType == 'monthly-day-of-month'){
          newDate = moment(this.paymentScheduleDayOfMonth)
        }
        else{
          newDate = moment(this.paymentScheduleDayOfWeek)
        }
        let currentDate = moment(new Date());
        let differenceInDays = newDate.diff(currentDate, 'days');
        if(differenceInDays > 30){
          this.changeDefaultDate();
        }
      }
    },


    canContinue(newVal, oldVal) {
      // the question is "next step disabled", which is the opposite of "canContinue", so we pass the inverse: !newVal
      bus.$emit('update-force-next-step-disabled', !newVal);
    },
    cardTabIsActive(newVal, oldVal) {
      if (!!newVal) {
        this.initCardForm();
      }
    },
    paymentScheduleType(newVal, oldVal){
      if(newVal == 'monthly-day-of-month'){
        this.paymentScheduleDayOfMonth = this.paymentScheduleDayOfWeek
      }
      else{
        this.checkValidDate();
      }
      this.canUpdatePaymentSchedule = true;
    },
    paymentScheduleDayOfWeek(newVal, oldVal){
      let newDate = moment(newVal)
      let currentDate = moment(new Date());
      // var differenceInDays = newDate.diff(currentDate, 'days');
      // if(differenceInDays > 30){

      //   this.changeDefaultDate();
      // }
      newDate = newDate.format('YYYY-MM-DD');
      if(this.currentPaymentSchedule){
        let currentSchedule = moment(this.currentPaymentSchedule.first_bill_date).format('YYYY-MM-DD');
        if(newDate != currentSchedule){
          this.canUpdatePaymentSchedule = true;
        }
        else{
          this.canUpdatePaymentSchedule = false
        }
      }else{
        this.canUpdatePaymentSchedule = true;
      }

      this.previouslySelectedDayOfWeek = oldVal;
      this.checkForPremiumChange();
    },
    paymentScheduleDayOfMonth(newVal, oldVal){
      let newDate = moment(newVal)
      let currentDate = moment(new Date());
      
      newDate = newDate.format('YYYY-MM-DD');

      if(this.currentPaymentSchedule){
        let currentSchedule = moment(this.currentPaymentSchedule.first_bill_date).format('YYYY-MM-DD');
        if(newDate != currentSchedule){
          this.canUpdatePaymentSchedule = true;
        }
      }
      else{
        this.canUpdatePaymentSchedule = true;
      }

      this.previouslySelectedDatOfMonth = oldVal;
      this.checkForPremiumChange();
    }
  },

  computed: {
    _defaultDayOfMonth() {
      const currentDate = moment()
      // Check if the current date is greater than 28th of the current month
      if (currentDate.date() != 1) {
        let nextMonthDate = moment(currentDate)
        nextMonthDate = nextMonthDate.add(1, 'months')
        nextMonthDate = nextMonthDate.date(1);
        // Check if the 1st of the next month is Saturday or Sunday
        if (nextMonthDate.day() === 0 /* Sunday */){
          return nextMonthDate.add(1, 'days').toDate()
        }
        else if(nextMonthDate.day() === 6 /* Saturday */) {
          return nextMonthDate.add(2, 'days').toDate();
        } else {
          return nextMonthDate.toDate();
        }
      }

      // If not greater than 28, return today's date
      return currentDate.toDate();
      // We removed this logic per request from CELIC - EF 2022-09-28
      // let defaultDayOfMonth = new Date().getDate();
      // if (defaultDayOfMonth < 1 || defaultDayOfMonth > 28) {
      //   defaultDayOfMonth = 1;
      // }
      // return defaultDayOfMonth;
    },

    maxDate(){
      const today = new Date();
      const tomorrow = new Date(today);
      if(this.paymentWithApp){
        tomorrow.setDate(tomorrow.getDate() + 30);
      }else{
        tomorrow.setDate(tomorrow.getDate() + 45);
      }

      return tomorrow.toISOString().split('T')[0];
    },

    paymentFirstNextText() {
      if (this.paymentWithApp === true) {
        return 'next payment after today';
      }
      return 'first payment and policy effective date';
    },

    paymentWithAppMade() {
      // If paymentWithAppProcessorId is Truthy, a *payment* was made for this app.
      // We should not allow any change to payment information.
      return (!!this.paymentWithAppProcessorId)
    },

    paymentMethodType() {
      if (this.bankTabIsActive) {
        return 'bank';
      }
      return 'card';
    },

    paymentInformationCaptured() {
      return this.paymentMethodId && this.paymentCustomerId;
    },

    paymentScheduleDaysOfMonth() {
      const ints = [...Array(28).keys()];
      return ints.map(i => {
        return {'value': i + 1, text: i + 1}
      });
    },

    firstNextPaymentDate() {
      let scheduled;
      const now = moment(new Date());

      if (this.paymentScheduleType === "monthly-day-of-month") {
        scheduled = moment(this.paymentScheduleDayOfMonth);
        
        if (!this.paymentScheduleDayOfMonth) {
          return;
        }

        // add a month if the day of month is in the past
        if (now.format('YYYY-MM-DD') === scheduled.format('YYYY-MM-DD')) {
          // scheduled.add(45, 'days');
          if(this.paymentWithApp){
            return scheduled.add(1, 'months').format("dddd, MMMM Do YYYY")
          }
          return "today";
        }

      } else if (this.paymentScheduleType === "monthly-day-of-week") {
        scheduled = moment(this.paymentScheduleDayOfWeek);
        if (!this.paymentScheduleDayOfWeek) {
          return;
        }

        let weekNumber = scheduled.date();
        let prefixes = ['0', '1', '2', '3', '4', '5'];
        weekNumber = parseInt(prefixes[0 | weekNumber / 7])+1;

        scheduled = moment(scheduled);

        console.log('scheduled', scheduled.format(), "after setting day of month to ", this.paymentScheduleDayOfWeek, "and every ", weekNumber,   moment(scheduled).format('dddd'), " of the month after that.");

        // add a month if the day of month is in the past
        if (now.format('YYYY-MM-DD') === scheduled.format('YYYY-MM-DD')) {
          // scheduled.add(45, 'days');
          if(this.paymentWithApp){
            if(this.forceWeekday){
              scheduled.add(1, 'months')
              while(scheduled.day() == 0 || scheduled.day()==6){
                scheduled.subtract(1, 'day');
              }
              this.forceWeekday = false;
              return scheduled.format("dddd, MMMM Do YYYY");
            }
            return scheduled.add(1, 'months').format("dddd, MMMM Do YYYY")
          }
          return 'today';
        }

      } else {
        return;
      }

      // if (this.paymentWithApp) {
      //   const daysOutThresholdDate = moment().hour(0).minute(0).second(0).add(46, 'days');
      //   const nextPaymentDate = scheduled.clone();
      //   let subsequentPaymentDate = null;
      //
      //   // the way we do this differs based on the schedule type
      //   if (this.paymentScheduleType === "monthly-day-of-week") {
      //     subsequentPaymentDate = nextPaymentDate.clone().add(5, 'weeks').day(this.paymentScheduleDayOfWeek);
      //   } else { // otherwise it must be "monthly-day-of-month:
      //     subsequentPaymentDate = nextPaymentDate.clone().add(1, 'months');
      //   }
      //
      //   if (moment(subsequentPaymentDate).isBefore(daysOutThresholdDate)) {
      //     scheduled = subsequentPaymentDate;
      //   } else {
      //     scheduled = nextPaymentDate;
      //   }
      // }

      return scheduled.format("dddd, MMMM Do YYYY");
    },

    firstNextPaymentDateString() {
      if (!this.firstNextPaymentDate) {
        return '';
      }
      return this.firstNextPaymentDate;
    },

    firstNextPaymentDateSecondString(){
      if (this.paymentScheduleType === "monthly-day-of-week"){
        let scheduled = moment(this.paymentScheduleDayOfWeek);
        if(scheduled.day()==0 || scheduled.day() == 6){
          while(scheduled.day()==0 || scheduled.day()==6) {
            scheduled.subtract(1, 'day');
          }
        }
        // let weekNumber = this.weekNumber(scheduled)
        let weekNumber = scheduled.date();
        // let prefixes = [
        //   '0', 1 = '1st', '2', '3', '4', '5'
        // ];

        let prefixes = {
          '0' : 0,
          '1' : '1st',
          '2' : '2nd',
          '3' : '3rd',
          '4' : '4th',
          '5' : '5th'
        }
        weekNumber = Math.ceil(weekNumber/7) ? (Math.ceil(weekNumber/7)).toString() : '1';
        weekNumber = prefixes[weekNumber];
        return weekNumber + " " + moment(scheduled).format('dddd')
      }else{
        return moment(this.paymentScheduleDayOfMonth).format("Do")
      }
    },

    canSubmitPaymentInformation() {
      if (this.paymentInformationCaptured) {
        return false;
      }

      if (this.bankTabIsActive) {
        if (this.bankAccount && this.bankRouting && this.bankRoutingIsValid) {
          return true;
        }

      } else if (this.cardTabIsActive) {
        if (this.cardExpMonth && this.cardExpYear && this.cardNumberIsValid && this.cardCvvIsValid) {
          return true;
        }
      }
      return false;
    },

    bankRoutingIsValid() {
      if (this.bankRouting) {
        return this.bankRouting.length === 9;
      }
      return null;
    }
  },
  methods: {


    async checkValidDate(){
      // Parse the date using Moment.js
      const date = moment(this.paymentScheduleDayOfMonth);
      const currentDate = moment();
      let shiftedDate = moment(this.paymentScheduleDayOfMonth);
      // Check if the day of the week is Saturday (6) or Sunday (0)
      let weekend = false;
      if (date.day() === 6) { // If Saturday
        shiftedDate.add(2, 'days');
        weekend = true // Move to Monday (2 days ahead)
      } else if (date.day() === 0) { // If Sunday
        shiftedDate.add(1, 'days'); // Move to Monday (1 day ahead)
        weekend = true
      }
      if(weekend){
        //Check end of rannge
        if ([29, 30, 31].includes(shiftedDate.date())) {
          console.log("The dates are 29,30,31")
          // Shift to the next weekday
          shiftedDate.add(1, 'months').date(1);
          // shiftedDate = shiftedDate.add(1, 'day');
          while (shiftedDate.day() === 0 || shiftedDate.day() === 6) {
            shiftedDate = shiftedDate.add(1, 'day');
          }
        }
        const differenceInDays = shiftedDate.diff(currentDate, 'days');
        let within30Days=false;
        let within45Days=false;
        if(this.paymentWithApp){
          within30Days = Math.abs(differenceInDays) <= 30;
        }
        else{
          within45Days = Math.abs(differenceInDays) <= 45;
        }

        if(within30Days || within45Days){
          //Check if beyond max age
          this.handleWeekDayMaxAge(shiftedDate)
        }else{
          //prior weekday
          this.recentAvailableWeekday(date) 
        }
      }
      else{
        this.paymentScheduleDayOfWeek = this.paymentScheduleDayOfMonth
      }
    },

    recentAvailableWeekday(date){
      let endDate;
      if(this.paymentWithApp){
        endDate = moment().add(30, 'days');
      }else{
        endDate = moment().add(45, 'days');
      }

      if (moment(date).isAfter(endDate)) {
        date = endDate;
      }
      if(date.isSameOrBefore(moment(), 'day')){
        this.paymentWithApp = true;
        this.forceWeekday = true;
        return;
      }
      while(date.date() === 29 || date.date()== 30 || date.date() == 31 || date.day()==0 || date.day()==6) {
          date.subtract(1, 'day');
      }
      if(date.isSameOrBefore(moment(), 'day')){
        this.paymentWithApp = true;
        this.forceWeekday = true;
        return;
      }
      this.paymentScheduleDayOfWeek = date.toDate();
    },

    async handleWeekDayMaxAge(date){
      let scheduled;
      scheduled = moment(date);
      scheduled = scheduled.format();
      let count = 0;
      const filteredApplicants = this.filterApplicantsByPaymentDate(this.applicants._applicants, scheduled)
      if(filteredApplicants.length > 0){
        for(const applicants of filteredApplicants){
          let age = this.calculateAge(applicants.data.date_of_birth)
          switch(this.rates[applicants.id].summary.actual_policy_rating){
            case "preferred": //85
              if(age==85){
                count ++;
              }
              break;

            case "standard":
              if(age==80){
                count ++;
              } //80
              break;
            case "graded": //75
              if(age==75){
                count ++;
              }
              break;
          }
        } 
        if(count>0){
          date.subtract(1, 'day');
          this.handleWeekDayMaxAge(date)
        }else if(filteredApplicants.length>0){
          date.subtract(1, 'day');
          while (date.day() === 0 || date.day() === 6) {
            date = date.subtract(1, 'day');
          }
          this.handleWeekDayMaxAge(date);
        }
        else{
          this.recentAvailableWeekday(date)
          return;
        } 
        
      }
      else{
        this.recentAvailableWeekday(date)
      }
    },

    setCurrentDate(){
      // this.$nextTick(() => {
        const currentDate = moment()
        this.paymentScheduleDayOfMonth = currentDate.toDate();
        this.paymentScheduleDayOfWeek = currentDate.toDate();
      // });
      this.firstLoad = false;
    },
    changeDefaultDate(){
      this.$nextTick(() => {
        this.paymentScheduleDayOfMonth = this._defaultDayOfMonth
        this.paymentScheduleDayOfWeek = this._defaultDayOfMonth
      });
    },
    async changePremium(){
      this.handleChangePremium().then(()=>{
        bus.$emit("update-coverage");
      })
    },
    async decreasePremium(){
      this.handleDecreasePremium().then(()=>{
        bus.$emit("update-coverage");
      })
    },
    async handleChangePremium(showOnly = false){
      if(this.firstLoad){
        this.paymentWithApp = true;
        return;
      }
      let date;
      if(this.paymentScheduleType === 'monthly-day-of-month'){
        date = moment(this.paymentScheduleDayOfMonth).format('YYYY-MM-DD')
      }
      else{
        date = moment(this.paymentScheduleDayOfWeek).format('YYYY-MM-DD')
      }
      // const applicants = await this.findApplicantsByName(this.applicants._applicants, this.applicantHistory)
      // let changeAll = false
      // if(applicants.length === this.applicants._applicants.length){
      //   changeAll = true
      // }
      let data = {
        enrollment_id: this.applicants._applicants[0].data.enrollment_session_id,
        effective_date:date,
        // decrease: false,
        // applicant_id: applicants.length >0 ? applicants[0].id: null,
        // dob: applicants.length >0 ? applicants[0].date_of_birth : null,
        // changeAll: changeAll
      }
      return Api.updateEffectiveDate(data).then((response) => {
        if(response.success){
          if(showOnly){
            bus.$emit('search-rates', true)
          }else{
            this.emitValue();
            bus.$emit("update-premium");
            this.premiumChanged = true;
            this.showChangePremium = false;
          }
        }
      }).catch((err) => {
          console.log("The error is", err)
      })

    },
    async findApplicantsByName(applicantsArray, namesArray) {
      const result = [];
      applicantsArray.forEach(applicant => {
        const firstName = applicant.data.first_name;
        if (namesArray.includes(firstName)) {
          // If the name is found, push id and date_of_birth to the result array
          result.push({
            id: applicant.id,
            date_of_birth: applicant.data.date_of_birth
          });
        }
      });

      return result;
    },
    async handleDecreasePremium(showOnly = false){
      let date;
      if(this.paymentScheduleType === 'monthly-day-of-month'){
        date = moment(this.paymentScheduleDayOfMonth).format('YYYY-MM-DD')
      }
      else{
        date = moment(this.paymentScheduleDayOfWeek).format('YYYY-MM-DD')
      }
      // const applicants = await this.findApplicantsByName(this.applicants._applicants, this.premiumChangedHistory)
      // if(applicants.length<1){
      //   return;
      // }
      // let changeAll = false
      // if(applicants.length === this.applicants._applicants.length){
      //   changeAll = true
      // }
      let data = {
        enrollment_id: this.applicants._applicants[0].data.enrollment_session_id,
        effective_date:date,
        // decrease: true,
        // applicant_id: applicants.length >0 ? applicants[0].id: null,
        // dob: applicants.length >0 ? applicants[0].date_of_birth : null,
        // changeAll: changeAll
      }
      return Api.updateEffectiveDate(data).then((response) => {
        if(showOnly){
          bus.$emit('search-rates', false)
        }
        else{
          if(response.success){
            this.emitValue();
            bus.$emit("update-premium");
            this.premiumChanged = false;
            this.showPremiumDecreased = false;
          }
        }


      }).catch((err) => {
          console.log("The error is", err)
      })
    },
    revertDatePremium(){
      this.paymentScheduleDayOfMonth= this.previouslySelectedDatOfMonth;
      this.paymentScheduleDayOfWeek= this.previouslySelectedDayOfWeek;
      this.showChangePremium = false;
      this.showMaxAgePopup= false;
    },
    revertDate(){
      if(this.paymentScheduleType === 'monthly-day-of-month'){
        this.paymentScheduleDayOfMonth= this.previouslySelectedDatOfMonth;
      }
      else{
        this.paymentScheduleDayOfWeek= this.previouslySelectedDayOfWeek;
      }
      this.showChangePremium = false;
      this.showMaxAgePopup= false;
    },
    weekNumber(scheduled){
      let weekNumber = scheduled.date();
      let prefixes = ['0', '1', '2', '3', '4', '5'];
      return Math.ceil(weekNumber/7);
    },
    dateDisabledDay(ymd, date) {
        // Disable weekends (Sunday = `0`, Saturday = `6`) and
        // disable days that fall on the 13th of the month
        const day = date.getDate()
        // Return `true` if the date should be disabled
        return day === 29 || day === 30 || day === 31
    },
    dateDisabled(ymd, date) {
        // Disable weekends (Sunday = `0`, Saturday = `6`) and
        // disable days that fall on the 13th of the month
        const weekday = date.getDay()
        const day = date.getDate()
        // Return `true` if the date should be disabled
        return weekday === 0 || weekday === 6 || day === 29 || day === 30 || day === 31
    },
    _applyCurrentPaymentScheduleLocally() {
      this.paymentMethodId = this.currentPaymentSchedule.payment_method_token;
      this.paymentCustomerId = this.currentPaymentSchedule.customer_token;
      this.paymentScheduleType = this.currentPaymentSchedule.schedule_type;
      this.paymentScheduleDayOfMonth = moment(this.currentPaymentSchedule.first_bill_date).format('YYYY-MM-DD'); //this.currentPaymentSchedule.day_of_month;
      this.paymentScheduleDayOfWeek = moment(this.currentPaymentSchedule.first_bill_date).format('YYYY-MM-DD');
      this.paymentScheduleWeekdayOccurrence = this.currentPaymentSchedule.weekday_occurrence;
      this.paymentWithApp = this.currentPaymentSchedule.payment_with_app;
      this.paymentWithAppDate = this.currentPaymentSchedule.payment_with_app_date;
      this.paymentWithAppProcessorId = this.currentPaymentSchedule.payment_with_app_processor_id;
    },

    initCardProcessor() {
      this.cardProcessor = new FattJs(process.env.VUE_APP_STAX_WEB_PAYMENTS_TOKEN, {
        number: {
          id: 'cardNumber',     // the html id of the div you want to contain the credit card number field
          placeholder: '0000 0000 0000 0000',    // the placeholder the field should contain
          style: 'height: 100%; width: 100%; font-size: 16px; line-height: 16px;',    // the style to apply to the field
          format: 'prettyFormat'    // the formatting of the CC number (prettyFormat || plainFormat || maskedFormat)
        },
        cvv: {
          id: 'cardCVV',    // the html id of the div you want to contain the cvv field
          placeholder: '000',    // the placeholder the field should contain
          type: 'text',    // the input type (optional)
          style: 'height: 100%; width: 100%; font-size: 16px; line-height: 16px;',    // the style to apply to the field
          // format: 'maskedFormat'
        }
      })
    },
    initBankProcessor() {
      this.bankProcessor = new FattJs(process.env.VUE_APP_STAX_WEB_PAYMENTS_TOKEN, {});
    },

    initCardForm() {
      this.cardProcessor
        .showCardForm()
          .then((handler) => {
            this.cardProcessor.on("card_form_incomplete", (message) => {
              this.cardType = message.cardType;
              this.cardNumberIsValid = !!message.validNumber;
              this.cardCvvIsValid = !!message.validCvv;
            });
            this.cardProcessor.on("card_form_complete", (message) => {
              this.cardType = message.cardType;
              this.cardNumberIsValid = !!message.validNumber;
              this.cardCvvIsValid = !!message.validCvv;
            });
          })
          .catch((err) => {
            console.error("There was an error loading the credit card form: ", err);
          });
    },

    clearPaymentInformation() {
      this.paymentCustomerId = null;
      this.paymentMethodId = null;
      this.emitValue();
    },

    cardFormIsValid() {
      if (!this.$refs.cardForm.checkValidity()) {
        return false;
      }
      if (!this.cardNumberIsValid) {
        return false;
      }
      if (!this.cardCvvIsValid) {
        return false;
      }
    },

    submitPaymentInformation() {
      // enable the curtain to hide
      this.showOverlay = true;

      this.pciErrors = [];

      let processor = null;

      let paymentInformation = {
        firstname: this.applicantContext.getFirstName(),
        lastname: this.applicantContext.getLastName(),
        person_name: this.applicantContext.getName(),
        // phone: this.applicantContext.getPhone(),
        address_1: this.applicantContext.getAddress(),
        address_2: this.applicantContext.getAddress2(),
        address_city: this.applicantContext.getApplicantCity(),
        address_state: this.applicantContext.getApplicantState(),
        address_zip: this.applicantContext.getApplicantZip(),
        method: this.paymentMethodType,
        validate: false,
      };

      if (this.bankTabIsActive) {
        if (!this.$refs.bankForm.checkValidity()) {
          return false;
        }

        processor = this.bankProcessor;

        Object.assign(
          paymentInformation,
          {
            bank_type: this.bankType,
            bank_account: this.bankAccount,
            bank_routing: this.bankRouting,
            bank_holder_type: this.bankHolderType,
          }
        );


      } else if (this.cardTabIsActive) {
        if (!this.cardFormIsValid()) {

          processor = this.cardProcessor;

          Object.assign(
            paymentInformation,
            {
              month: this.cardExpMonth,
              year: this.cardExpYear,
            }
          );
        }
      }

      processor
        .tokenize(paymentInformation)
        .then((response) => {
          this.paymentMethodId = response.id;
          this.paymentCustomerId = response.customer_id;
          return this.saveSchedule();
        })
        .catch((err) => {
          this.pciErrors = [];
          if (err.key && err.key === 'errors.expired') {
            this.pciErrors = ['The provided exp month and year has passed.']
          } else if (err.errors) {
            this.pciErrors = err.errors;
          }
        })
        .finally(() => {
          this.showOverlay = false;
        })
    },

    checkWeekend(date){
      if(date.day()==0 || date.day()==6){
        while(date.day() == 0 || date.day()==6){
          date.subtract(1, 'day');
        }
        return date
      }
      return date
    },

    saveSchedule() {
      if (!this.firstNextPaymentDateString) {
        return;
      }

      // enable the curtain to hide
      this.showOverlay = true;

      this.canContinue = false;

      this.canUpdatePaymentSchedule = false;

      let scheduleData = {
        schedule_type: this.paymentScheduleType,
        day_of_month: this.paymentScheduleType === 'monthly-day-of-month' ? moment(this.paymentScheduleDayOfMonth).format('DD') : null,
        day_of_week: this.paymentScheduleType === 'monthly-day-of-week' ? this.checkWeekend(moment(this.paymentScheduleDayOfWeek)).day() : null,
        day_of_week_str: this.checkWeekend(moment(this.paymentScheduleDayOfWeek)).format('dddd'),
        weekday_occurrence: this.weekNumber(this.checkWeekend(moment(this.paymentScheduleDayOfWeek))),
        customer_token: this.paymentCustomerId,
        payment_method_token: this.paymentMethodId,
        payment_with_app: this.paymentWithApp,
        next_payment_date: this.firstNextPaymentDate,
        // don't update payment_with_app_date or payment_with_app_processor_id! They should only be set
        // by the backend.
      };

      return Api.savePaymentSchedule(scheduleData)
        .then((response) => {
            this.paymentMethodId = response.payment_method_token;
            this.paymentCustomerId = response.customer_token;

            // so the value can be updated on the event bus
            this.emitValue();

            // now we are good to advance
            this.canContinue = true;
          })
          .catch((err) => {
            this.canUpdatePaymentSchedule = true;  // re-enable because something happened
            this.pciErrors = [];
            for (const errKey in err) {
              let errorMessage = err[errKey];
              // strip off the prefix from the error message "Could not tokenize payment method:"
              errorMessage = errorMessage.replace(/Could not tokenize payment method:/, '').trim();
              // replace any remaining instance of "tokenize" with "save" because that sounds a lot nicer
              errorMessage = errorMessage.replace(/tokenize/g, 'save');
              this.pciErrors.push(errorMessage);
            }
          })
          .finally(() => {
            this.showOverlay = false;
          })
    },

    emitValue() {
      bus.$emit("payment-information-updated", {
        paymentMethodId: this.paymentMethodId,
        paymentCustomerId: this.paymentCustomerId,
        paymentScheduleType: this.paymentScheduleType,
        paymentScheduleDayOfMonth: this.paymentScheduleDayOfMonth,
        paymentScheduleDayOfWeek: this.paymentScheduleDayOfWeek,
        paymentScheduleWeekdayOccurrence: this.paymentScheduleWeekdayOccurrence,
        paymentWithApp: this.paymentWithApp,
        paymentWithAppMade: this.paymentWithAppMade,
      });
    },


    // weekday and month params are 0-index
    getDateOfWeekdayOccurrence(weekday, occurrence, month, year) {
      let currentOccurrence = 0;
      const maxOccurrence = 4;
      const maxDateDay = 28;
      let startDate = new Date(year, month, 1);
      const now = new Date()

      for (let x = 0; x < maxDateDay; x++) {
        let currentSearchDate = new Date(startDate.getFullYear(), startDate.getMonth(), x + 1);
        if (currentSearchDate.getDay() === weekday) {
          // this is the right day...
          currentOccurrence++;

          if (currentOccurrence === occurrence) { // this is the nth occurrence

            if (now > currentSearchDate) {
              let newInvoiceDate = this.getDateOfWeekdayOccurrence(weekday, occurrence, month + 1, year);
              return newInvoiceDate;
            }

            return moment(currentSearchDate);

          }
        }
      }

      if (currentOccurrence > maxOccurrence) {
        console.error(`getDateOfWeekdayOccurrence had an error -- did not find day with given occurrence ${occurrence} of weekday ${weekday}`);
      }

      return null;
    },
    isPaymentDateAfterNextBirthday(paymentDate, dob) {
      const paymentDateObj = moment(paymentDate, 'YYYY-MM-DD')
      let nextBirthday = this.calculateNextBirthday(dob);
      nextBirthday = moment(nextBirthday,'YYYY-MM-DD' )
      if (paymentDateObj.isAfter(nextBirthday) || paymentDateObj.isSame(nextBirthday)) {
        return true;
      }
      // If the payment date is earlier than the next birthday
      return false;
    },

    filterApplicantsByPaymentDate(applicants, paymentDate) {
      return applicants.filter(applicant => {
        const { date_of_birth } = applicant.data;
        return this.isPaymentDateAfterNextBirthday(paymentDate, date_of_birth);
      });
    },

    calculateNextBirthday(dob) {
      const dateOfBirth = moment(dob);
        // Create a moment object for today
        const today = moment();

        // Create a moment object for the next birthday
        const nextBirthday = moment(dateOfBirth);
        nextBirthday.year(today.year());

        // If the next birthday has already occurred this year, set it to next year
        if (today.isAfter(nextBirthday)) {
            nextBirthday.add(1, 'year');
        }

        // Calculate the difference in days until the next birthday
        // const daysUntilNextBirthday = nextBirthday.diff(today, 'days');
        return nextBirthday.format('YYYY-MM-DD')
        // daysUntilNextBirthday: daysUntilNextBirthday
    },

    calculateAge(dateOfBirth){
      let dob = new Date(dateOfBirth);  
      //calculate month difference from current date in time  
      let month_diff = Date.now() - dob.getTime();  
        
      //convert the calculated difference in date format  
      let age_dt = new Date(month_diff);   
        
      //extract year from date      
      let year = age_dt.getUTCFullYear();  
        
      //now calculate the age of the user  
      let age = Math.abs(year - 1970); 
      return age; 
    },

    async handlePremiumChangeState(date){
      
      const filteredApplicants = this.filterApplicantsByPaymentDate(this.applicants._applicants, date)
      if(filteredApplicants.length > 0){
        let maxAgeApplicants = [];
        for(const applicants of filteredApplicants){
          let age = this.calculateAge(applicants.data.date_of_birth)
          switch(this.rates[applicants.id].summary.actual_policy_rating){
            case "preferred": //85
              if(age==85){
                maxAgeApplicants.push(applicants.data.first_name)
              }
              break;

            case "standard":
              if(age==80){
                maxAgeApplicants.push(applicants.data.first_name)
              } //80
              break;
            case "graded": //75
              if(age==75){
                maxAgeApplicants.push(applicants.data.first_name)
              }
              break;
          }
          //If already at max age
        } 
        if(maxAgeApplicants.length>0){
          this.maxAgeApplicants = maxAgeApplicants;
          if(this.firstLoad){
            this.paymentWithApp = true;
            return;
          }
          this.showMaxAgePopup = true;
          return;
        }   
      }
      else{
        this.firstLoad = false;
        // return;
      }

      const firstNamesArray = filteredApplicants.map(applicant => applicant.data.first_name);

      let differenceArray = this.applicantHistory.filter(element => !firstNamesArray.includes(element));
      if(!(this.applicantHistory.length === firstNamesArray.length && this.applicantHistory.every(value => firstNamesArray.includes(value)))){
        this.applicantHistory = firstNamesArray;
        if(differenceArray.length>0){
         // this.showPremiumDecreased = true;
         this.premiumChangedHistory = differenceArray;
        await this.handleDecreasePremium(true)
        return
        }
        await this.handleChangePremium(true)
      }
    },

    handleUpdatedRates(rates){
      this.previousPremium = 0;
      this.changedPremium = 0
      
      for (const obj of rates.updatedRates) {
        try {
          if(obj.main_coverage){
            const premium = parseFloat(obj.main_coverage);
            this.changedPremium += premium || 0;
            const prevPremium = parseFloat(rates.data.data.selectedCoverages[obj.applicant_id].data.final_expense_coverage.premium);
            this.previousPremium += prevPremium || 0;
          }
           // Add premium to sum, or add 0 if premium is undefined
        } catch (error) {
          // Handle potential undefined property access (optional)
          console.error('Error accessing premium:', error.message);
        }
      }
      if(rates.increase){
        this.showChangePremium = true;
      }
      else{
        this.showPremiumDecreased = true;
      }
    },
    checkForPremiumChange(){
      this.premiumChangedHistory=[]
      let scheduled;
      const now = moment(new Date());
     
      if (this.paymentScheduleType === "monthly-day-of-month") {
        scheduled = moment(this.paymentScheduleDayOfMonth);

        if (!this.paymentScheduleDayOfMonth) {
          return;
        }
        this.handlePremiumChangeState(scheduled.format())
      } else if (this.paymentScheduleType === "monthly-day-of-week") {
          scheduled = new Date(this.paymentScheduleDayOfWeek);
        if (!this.paymentScheduleDayOfWeek) {
          return;
        }

        let weekNumber = scheduled.getDate();
        let prefixes = ['0', '1', '2', '3', '4', '5'];
        weekNumber = parseInt(prefixes[0 | weekNumber / 7])+1;

        scheduled = moment(scheduled);

        this.handlePremiumChangeState(scheduled.format())
      }
    }
  }
}
</script>

<style scoped>
/* this styles the <IFRAME>d form fields that Fatt.js creates, so they look
   consistent with our Bootstrap 4 styling - EF 2022-03-25 */
#cardCVV, #cardNumber {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
}
</style>
